<template>
  <section v-if="isLogin === 'render'">
    <div class="mainContainer">
      <div>
        <div class="grid">
          <div>
            <VCarousel
              hide-controls
              hide-delimiters
              class="elevation-0 image"
            >
              <VCarouselItem
                v-for="(img,i) in randImg"
                :key="i"
                :src="require(`@/assets/images/bg/${img}`)"
                lazy
              />
            </VCarousel>
          </div>
          <section
            v-if="currentPage === 'error'"
            class="loginContent"
          >
            <div class="topRound" />
            <div class="loginLayout">
              <Error @retry="retryLogin" />
            </div>
          </section>
          <section
            v-if="currentPage === 'invalid'"
            class="loginContent"
          >
            <div class="topRound" />
            <div class="loginLayout">
              <Invalid @retry="retryLogin" />
            </div>
          </section>
          <section
            v-if="currentPage === 'getID'"
            class="loginContent"
          >
            <div class="topRound" />
            <div class="loginLayout">
              <div class="d-flex">
                <div>
                  <img
                    :src="require('../assets/images/logo.png')"
                    class="smallLogo"
                  >
                </div>
                <div>
                  <p class="textTitleMain">
                    Welcome To
                  </p>
                  <p class="textSubTitle">
                    Future Laureates Academy
                  </p>
                </div>
              </div>
              <p class="textSubTitleNormal text-uppercase mt-2">
                Login With Admission Number
              </p>
              <div class="pt-2">
                <VTextField
                  v-model="studentIdInput"
                  color="primary"
                  class="textBold"
                  label="Admission Number"
                  placeholder="XXX-XXXX"
                  mask="###-####"
                  prefix="FLA-"
                  :hide-details="true"
                  :loading="isLoading"
                  type="tel"
                />
                <VueRecaptcha
                  ref="recaptcha"
                  class="recaptcha-container"
                  :sitekey="sitekey"
                  size="invisible"
                  @verify="onVerify"
                  @expired="onExpired"
                />
              </div>
            </div>
          </section>

          <section
            v-if="currentPage === 'getOTP'"
            class="loginContent"
          >
            <div class="topRound" />
            <div class="loginLayout">
              <p class="textTitle">
                Hey, <span v-if="this.$store.state.studentInfo !== null">
                  {{ this.$store.state.studentInfo.name.split(' ')[0] }}
                </span> <span v-else>Stranger</span>!
              </p>
              <p class="textSubTitleNormal">
                Not
                <span
                  v-if="this.$store.state.studentInfo !== null"
                  class="notUser"
                  @click="retryLogin"
                >
                  {{ this.$store.state.studentInfo.name.split(' ')[0] }}
                </span>
                <span
                  v-else
                  class="notUser"
                  @click="retryLogin"
                >Stranger</span>?
              </p>
              <div
                v-if="this.$store.state.studentInfo !== null"
                class="mt-4"
              >
                <p class="textSubTitleNormal">
                  Select A Phone Number To Get OTP
                </p>
                <div>
                  <VRadioGroup v-model="otpNo">
                    <VRadio
                      v-for="(n,i) in this.$store.state.studentInfo.phNo"
                      :key="i"
                      :label="`${n.substring(0, 1)}xxxxxx${n.substring(7)}`"
                      :value="n"
                      color="radio"
                      class="textSubTitleNormal"
                    />
                  </VRadioGroup>
                  <VFadeTransition>
                    <VBtn
                      v-if="otpNoSelected"
                      class="ma-0"
                      depressed
                      round
                      color="primary"
                      :disabled="isOtpSent"
                      :loading="isOtpSentLoading"
                      @click="sendOTP(otpNo)"
                    >
                      {{ isOtpResend? 'Resend OTP' :'Send OTP' }}
                      <template v-slot:loader>
                        <span>SMS Sent</span>
                      </template>
                    </VBtn>
                  </VFadeTransition>
                </div>
              </div>
              <VFadeTransition>
                <div
                  v-if="otpSent"
                  class="mt-3 mb-3"
                >
                  <VTextField
                    v-model="enterOTP"
                    color="primary"
                    class="textBold mb-0 w200"
                    label="Enter OTP"
                    mask="#-#-#-#-#-#"
                    placeholder="x-x-x-x-x-x"
                    :hide-details="true"
                    :loading="isLoading"
                    type="tel"
                  />
                </div>
              </VFadeTransition>
            </div>
            <div id="recaptcha-container" />
          </section>
        </div>
      </div>
    </div>
    <VSnackbar
      v-model="isErr"
      :timeout="30000"
      multi-line
      color="primary"
    >
      <VListTile>
        <VListTileContent class="textBold textSmall">
          {{ isErrMsg }}
        </VListTileContent>
        <VListTileAction @click="isErr = false">
          <VBtn
            icon
            ripple
          >
            <VIcon dark>
              close
            </VIcon>
          </VBtn>
        </VListTileAction>
      </VListTile>
    </VSnackbar>
  </section>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import VueRecaptcha from 'vue-recaptcha';
import firebase from 'firebase/app';
import 'firebase/auth';
import '../configuration/fire';
import store from '../store';
import api from '../configuration/api';
import Error from '../components/Error.vue';
import Invalid from '../components/Invalid.vue';

export default {
  components: {
    VueRecaptcha,
    Error,
    Invalid,
  },
  data: () => ({
    isLogin: false,
    currentPage: 'getID',
    isLoading: false,
    isErr: false,
    isErrMsg: '',
    otpNoSelected: false,
    otpSent: false,
    isOtpSent: false,
    isOtpSentLoading: false,
    isOtpResend: false,
    studentIdInput: '',
    otpNo: '',
    enterOTP: '',
    otpRecaptchaCB: '',
    sitekey: '6Lf-ZJ8UAAAAAODapkWsnmKjuGt9xOa8CG6p5un1',
    imgArray: [
      '1.jpg',
      '2.jpg',
      '3.jpg',
      '4.jpg',
      '5.jpg',
      '6.jpg',
    ],
  }),
  computed: {
    randImg() {
      return _.shuffle(this.imgArray);
    },
    studentID() {
      const stdIdInp = this.studentIdInput;
      const stdIdFormatted = `FLA-${stdIdInp.substring(0, 3)}-${stdIdInp.substring(3)}`;
      return stdIdFormatted;
    },
  },
  watch: {
    studentID(val) {
      if (val.length === 12) {
        this.isLoading = true;
        this.$refs.recaptcha.execute();
      }
      if (val.length === 5) {
        this.$refs.recaptcha.reset();
        this.isLoading = false;
      } else if (val.length > 11) {
        this.isLoading = true;
      }
    },
    async enterOTP(val) {
      if (val.length === 6) {
        this.isLoading = true;
        await this.verifyOTP(val);
      } else {
        this.isLoading = false;
      }
    },
    otpNo(v) {
      if (v) {
        this.otpNoSelected = true;
      }
    },
  },
  beforeMount() {
    if (this.$store.state.currentUser != null) {
      this.$router.push('/');
    } else {
      this.isLogin = 'render';
    }
  },
  methods: {
    initRecaptcha() {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', { size: 'invisible' });
      this.otpRecaptchaCB = window.recaptchaVerifier;
    },
    retryLogin() {
      this.currentPage = 'getID';
    },
    onVerify(cb) {
      store.commit('setStudentID', this.studentID);
      const instance = axios.create();
      instance.post(`${api}/student/check`, { admNo: this.$store.state.studentID, cb })
        .then((res) => {
          this.isLoading = false;
          const resStatus = res.data.status;
          if (resStatus === 'success') {
            store.commit('setStudentInfo', res.data);
            this.currentPage = 'getOTP';
            setTimeout(() => {
              this.initRecaptcha();
            }, 1000);
          } else if (resStatus === 'error') {
            store.commit('setStudentInfo', null);
            this.currentPage = 'error';
          } else {
            store.commit('setStudentInfo', null);
            this.currentPage = 'invalid';
          }
        });
    },
    verifyOTP(code) {
      window.confirmationResult.confirm(code)
        .then(() => {
          setTimeout(() => {
            this.$router.push('/');
            this.isOtpSentLoading = false;
          }, 100);
        }).catch(() => { });
    },
    sendOTP(phNo) {
      this.isOtpSent = true;
      const phoneNumber = `+91${phNo}`;
      firebase.auth().signInWithPhoneNumber(phoneNumber, this.otpRecaptchaCB)
        .then((res) => {
          window.confirmationResult = res;
          this.isOtpSent = true;
          this.isOtpSentLoading = true;
          this.otpSent = true;
          setTimeout(() => {
            this.isOtpSentLoading = false;
            this.isOtpSent = false;
            this.isOtpResend = true;
          }, 60000);
        })
        .catch(() => {
          this.isErr = true;
          this.isErrMsg = 'OTP Limit Reached, Please Try Again Later...';
          this.isOtpSentLoading = false;
          this.isOtpSent = true;
          setTimeout(() => {
            this.isOtpSentLoading = false;
            this.isOtpSent = false;
          }, 120000);
        });
    },
    onExpired() {
      this.$refs.recaptcha.reset();
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../assets/stylus/text';
@import '../assets/stylus/login';
</style>
