<template>
  <section>
    <div>
      <p class="textTitle">
        Error
      </p>
      <p class="textSubTitle mb-5">
        Something Went Wrong, Please Try Again Later.
      </p>
      <div>
        <VBtn
          class="ma-0"
          depressed
          round
          color="primary"
          @click="onClick"
        >
          Retry <VIcon
            small
            right
          >
            refresh
          </VIcon>
        </VBtn>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    onClick() {
      this.$emit('retry');
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../assets/stylus/text';
</style>
